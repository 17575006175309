<template>
  <div>qrcode</div>
</template>
<script>
export default {
  name: 'ParQrcode',
  data() {

  }
}
</script>
